import { defineStore } from 'pinia';
import { computed, ref } from 'vue';
import { UserUpdatedData } from '@/types/users';
import { companyApi } from '~/api/company';
import { Company, Prompt } from '~/types/companies';
import { userApi } from '~/api/user';
import { useUserStore } from '~/stores/user';
import { isErrorMessageResponse, isUser } from '~/types/guards/api-responses';
import { keyBy } from 'lodash';
import { Report } from '~/types/report';
import { filesApi } from '~/api/files';

/**
 * Company store.
 */

export const useCompanyStore = defineStore('company', () => {
  const company = ref<Company>(null);
  const prompts = ref<null | Prompt[]>(null);

  const reports = ref<Report[]>([]);

  const loading = ref<boolean>(false);
  const error = ref<null | string>(null);
  const success = ref<null | string>(null);

  async function hydrate() {
    const companyId = useUserStore().user.company.id;
    await this.getById(companyId);
    await this.getReports();
  }

  function $reset() {
    company.value = null;
    reports.value = null;
    prompts.value = null;
  }

  async function dehydrate() {
    this.$reset();
  }

  async function getById(id: number | string) {
    if (!id) throw new Error('id not provided');
    loading.value = true;
    try {
      const res = await companyApi.getById(id);
      if (isErrorMessageResponse(res)) {
        error.value = res.errorCode;
        return 'error';
      }
      company.value = res;
      await this.getCompanyPositions();
    } catch (error) {
      error.value = error?.message || 'error getting company';
    } finally {
      loading.value = false;
    }
  }

  const freeBalance = computed(() => {
    const balance = company?.value?.balance;
    if (!balance) return 0;
    const assigned = company.value.users.reduce((acc, user) => {
      return acc + user.balance + user.holdBalance;
    }, 0);
    const result = balance - assigned;
    return result > 0 ? result : 0;
  });

  async function addEmployee(companyId: string | number, data: UserUpdatedData) {
    this.loading = true;
    this.error = null;
    this.success = null;

    try {
      const res = await companyApi.addEmployee(data);
      //todo create prettier text getter
      if (isErrorMessageResponse(res)) {
        if (res.errorCode === 'ALREADY_EXIST') {
          this.error = 'Пользователь с таким номером телефона уже существует';
          return 'error';
        }
        this.error = res.errorCode;
        return 'error';
      }
      if (isUser(res)) {
        const user = { ...res, avatarUrl: null, countUploadFiles: 0, usedMinutes: 0 };
        company.value.users.unshift(user);
        this.success = 'Сотрудник добавлен';
        return 'ok';
      }
    } catch (error) {
      this.error = 'Не удалось создать пользователя';
      return 'error';
    } finally {
      this.loading = false;
    }
  }

  const users = computed(() => {
    if (!company.value) return [];
    return company.value.users.map((user) => ({
      ...user,
      //чтобы не было null в таблице ?
      firstname: user.firstname,
      lastname: user.lastname,
      filesUploaded: user?.countUploadFiles || 0,
      usedBalance: user?.usedBalance || 0,
      balance: user?.balance || 0,
    }));
  });

  const usersNormalized = computed(() => {
    return keyBy(users.value, 'id');
  });

  async function updateEmployee(id: number | string, data: UserUpdatedData) {
    if (!this.company) return;
    loading.value = true;
    error.value = null;
    success.value = null;

    //todo cleanup types of minutes
    try {
      const res = await userApi.update(id, data);
      if (isErrorMessageResponse(res)) {
        error.value = res.errorCode;
        return 'error';
      }
      if (isUser(res)) {
        const userIdx = company.value.users.findIndex((user) => user.id === id);
        if (userIdx !== -1) {
          const { avatar, ...update } = res;
          company.value.users[userIdx] = {
            ...company.value.users[userIdx],
            ...update,
          };
        }
        success.value = 'Данные сотрудника обновлены';
        //for the sidebar
        if (Number(id) === Number(useUserStore()?.user?.id)) {
          await useUserStore().hydrate();
        }
      }
    } catch (error) {
      error.value = 'Не удалось обновить данные сотрудника';
      return 'error';
    } finally {
      loading.value = false;
    }
  }

  const managers = computed(() => {
    if (!users.value?.length) {
      return [];
    }
    return users.value.filter((employee) => employee?.positionId === 1);
  });

  const companyPositionsList = ref([]);

  async function getCompanyPositions() {
    try {
      const res = await companyApi.getCompanyPositions();
      if (!Array.isArray(res)) {
        throw new Error('Ошибка при получении списка должностей в компании');
      }
      companyPositionsList.value = res;
    } catch (error) {
      error.value = error?.message || 'Не удалось получить список должностей';
    }
  }

  function getEmployeeName(id: number) {
    const person = usersNormalized.value[id];
    if (!person) {
      return `Указанный менеджер не найден в\u00A0компании ${company.value.name}`;
    }
    if (person && person?.firstname && person?.lastname) {
      return `${person.firstname} ${person.lastname}`;
    }
    if (person && !person?.lastname) {
      return `${person.email}`;
    }
    return person ? person.username : null;
  }

  async function getReports(): Promise<'ok' | 'error'> {
    loading.value = true;
    error.value = null;
    try {
      const res = await companyApi.getReportsList();
      if (isErrorMessageResponse(res)) {
        throw new Error(res.errorCode);
      }
      if (res) {
        reports.value = res;
        return 'ok';
      }
    } catch (error) {
      error.value = 'Не удалось получить отчеты компании';
      return 'error';
    } finally {
      loading.value = false;
    }
  }

  async function getAnalysisPrompts() {
    if (!company.value) {
      return;
    }
    loading.value = true;
    error.value = null;
    try {
      const res = await filesApi.getPrompts(company.value.id);
      if (Array.isArray(res)) {
        prompts.value = res;
        return 'ok';
      } else {
        error.value = 'Ошибка при запросе списка промтов';
        return 'error';
      }
    } catch (_err) {
      error.value = 'Ошибка при запросе списка промтов';
      return 'error';
    } finally {
      loading.value = false;
    }
  }

  const promptsNormalized = computed(() => {
    if (!prompts.value?.length) {
      return null;
    }
    return keyBy(prompts.value, 'id');
  });

  function updateBalance(balance: number) {
    company.value.balance = balance;
  }

  return {
    updateBalance,
    prompts,
    promptsNormalized,
    getAnalysisPrompts,
    reports,
    getReports,
    getEmployeeName,
    getCompanyPositions,
    companyPositionsList,
    managers,
    $reset,
    hydrate,
    dehydrate,
    usersNormalized,
    success,
    freeBalance,
    updateEmployee,
    users,
    company,
    addEmployee,
    getById,
    loading,
    error,
  };
});
